import React, { useEffect, useState } from "react"

import { Layout } from "../../components/Layout"
import { SEO } from "../../components/SEO"

import "./returns.scss"

const ReturnsPage = ({ location }) => {
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const { search } = location
    if (search) {
      const query = search.slice(1).split("=")
      if (query[0] === "completed" && query[1] === "true") {
        setCompleted(true)
      }
    }
  }, [location])

  return (
    <Layout>
      <SEO
        description="If you are not completely satisfied with your purchase or the product is found to be defective, you may return the item to us in its original condition within 14 days of receipt."
        title="Return Policy"
      />
      <section className="container returns-container">
        <div className="returns-body">
          <h1>Return Policy</h1>
          <p>
            If you are not completely satisfied with your purchase or the
            product is found to be defective, you may return the item to us in
            its original condition within 14 days of receipt. All items must be
            returned unworn, unwashed, unaltered, and in their resalable
            condition with all tags originally attached. Defective items will be
            replaced free of charge for the customer.
          </p>
          <p>
            Refunds will be issued based on your original form of payment once
            the items have been recieved and confirmed to be in the proper
            condition. Please allow at least 10 business days for refunds to be
            processed.
          </p>
          <p>
            Customer is responsible for all shipping costs for returns. Shipping
            and handling charges are non-refundable.
          </p>
          <p>
            If your items qualify for return, please fill out the form below to
            start the return process.
          </p>
        </div>
        <div className="returns-form">
          <h2>Return Form</h2>
          {completed && (
            <p className="result">
              Your return has been submitted. We'll get back to you shortly.
            </p>
          )}
          <form
            className="contact-form"
            name="returns"
            method="post"
            action="/returns/?completed=true"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="returns" />
            <div className="field">
              <label htmlFor="name">Name</label>
              <input
                className="input"
                type="text"
                name="name"
                id="name"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="email">Email</label>
              <input
                className="input"
                type="email"
                name="email"
                id="email"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="order-number">Order #</label>
              <input
                className="input"
                type="text"
                name="order-number"
                id="order-number"
                required
              />
            </div>
            <div className="field">
              <label htmlFor="message">Reason For Return</label>
              <textarea
                className="input"
                name="reason"
                id="reason"
                rows="4"
                required
              />
            </div>
            <input className="btn" type="submit" value="Submit" />
          </form>
        </div>
      </section>
    </Layout>
  )
}
export default ReturnsPage
